<template>
    <ion-page>
        <div>События</div>
    </ion-page>
</template>

<script>
export default {
    name: 'AdminEvents',
};
</script>

<style scoped>

</style>
